
require.register("list.js/src/utils/index-of.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "list.js");
  (function() {
    var indexOf = [].indexOf

module.exports = function(arr, obj){
  if (indexOf) return arr.indexOf(obj);
  for (var i = 0, il = arr.length; i < il; ++i) {
    if (arr[i] === obj) return i;
  }
  return -1
}
  })();
});