
require.register("list.js/src/add-async.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "list.js");
  (function() {
    module.exports = function (list) {
  var addAsync = function (values, callback, items) {
    var valuesToAdd = values.splice(0, 50)
    items = items || []
    items = items.concat(list.add(valuesToAdd))
    if (values.length > 0) {
      setTimeout(function () {
        addAsync(values, callback, items)
      }, 1)
    } else {
      list.update()
      callback(items)
    }
  }
  return addAsync
}
  })();
});