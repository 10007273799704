
require.register("list.js/src/utils/extend.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "list.js");
  (function() {
    /*
 * Source: https://github.com/segmentio/extend
 */

module.exports = function extend(object) {
  // Takes an unlimited number of extenders.
  var args = Array.prototype.slice.call(arguments, 1)

  // For each extender, copy their properties on our object.
  for (var i = 0, source; (source = args[i]); i++) {
    if (!source) continue
    for (var property in source) {
      object[property] = source[property]
    }
  }

  return object
}
  })();
});